import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import ReactMoment from 'react-moment';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Box,
  Button,
  Link as MuiLink,
  Menu,
  MenuItem,
} from '@material-ui/core';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import SlideshowIcon from '@material-ui/icons/Slideshow';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import { items } from '../utils';
import ShareButtons from './ShareButtons';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: `${20 / 16}rem`,
    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.h4.fontSize,
    },
  },
  timestamp: {
    cursor: 'default',
    color: theme.palette.grey[500],
  },
  viewing: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      marginBottom: theme.spacing(2),
    },
  },
  downloadButton: {
    marginRight: 0,
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(2),
    },
  },
  slidesViewButton: ({ slidesView }) => ({
    color: slidesView ? theme.palette.success.main : 'inherit',
    marginRight: theme.spacing(2),
  }),
  slidesButtonSuffix: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'inline',
    },
  },
  mediaButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      marginRight: 0,
    },
  },
  extension: {
    color: theme.palette.text.hint,
    marginLeft: theme.spacing(4),
    flexGrow: 1,
    textAlign: 'right',
    fontSize: theme.typography.body2.fontSize,
  },
}));

const SermonSummary = ({
  video,
  audio,
  sermon,
  slidesView,
  onSlidesViewClick,
}) => {
  const [downloadAnchor, setDownloadAnchor] = useState(null);
  const classes = useStyles({ slidesView });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Typography variant="h4" component="h1" className={classes.title}>
          {sermon.title}
        </Typography>

        <Typography variant="h5">
          {items(sermon.speakers).map(({ name, slug }, i) => (
            <React.Fragment key={slug}>
              <MuiLink component={Link} to={`/speakers/${slug}`}>
                {name}
              </MuiLink>
              {i !== items(sermon.speakers).length - 1 && ', '}
            </React.Fragment>
          ))}
        </Typography>

        <Typography variant="body2" className={classes.timestamp}>
          <ReactMoment format="MMMM D, YYYY">{sermon.date}</ReactMoment>
        </Typography>
      </Grid>

      <Grid item xs={12} sm={6}>
        {(sermon.audioUrl || sermon.slides) && (
          <Box className={classes.viewing}>
            {(sermon.audioUrl || sermon.slides) && (
              <Box className={classes.downloadButton}>
                <Button
                  size="small"
                  startIcon={<CloudDownloadIcon />}
                  onClick={(event) => setDownloadAnchor(event.currentTarget)}
                >
                  Download
                </Button>
                <Menu
                  keepMounted
                  anchorEl={downloadAnchor}
                  open={Boolean(downloadAnchor)}
                  onClose={() => setDownloadAnchor(null)}
                >
                  {sermon.audioUrl && (
                    <MenuItem
                      component="a"
                      href={`${sermon.audioUrl}?source=direct&download`}
                    >
                      Audio
                      <div className={classes.extension}>mp3</div>
                    </MenuItem>
                  )}
                  {sermon.slides && (
                    <MenuItem download component="a" href={sermon.slides}>
                      Slides
                      <div className={classes.extension}>pdf</div>
                    </MenuItem>
                  )}
                </Menu>
              </Box>
            )}

            {video && sermon.slides && (
              <Box className={classes.slidesViewButton}>
                <Button
                  size="small"
                  color="inherit"
                  startIcon={<SlideshowIcon />}
                  href={sermon.slides}
                  onClick={onSlidesViewClick}
                >
                  <span>
                    Slides
                    <span className={classes.slidesButtonSuffix}> View</span>
                  </span>
                </Button>
              </Box>
            )}

            <Box className={classes.mediaButton}>
              {video && sermon.audioUrl && (
                <Button
                  size="small"
                  startIcon={<VolumeUpIcon />}
                  component={Link}
                  to={`/media/sermons/${sermon.slug}/listen`}
                  variant="contained"
                >
                  Listen
                </Button>
              )}

              {audio && (
                <Button
                  size="small"
                  startIcon={<PlayArrowIcon />}
                  component={Link}
                  to={`/media/sermons/${sermon.slug}`}
                  variant="contained"
                >
                  Watch
                </Button>
              )}
            </Box>
          </Box>
        )}

        <ShareButtons
          title={`${sermon.title} by ${items(sermon.speakers)
            .map(({ name }) => name)
            .join(', ')}`}
          align="right"
        />
      </Grid>
    </Grid>
  );
};

SermonSummary.propTypes = {
  video: PropTypes.bool,
  audio: PropTypes.bool,
  sermon: PropTypes.object.isRequired,
  slidesView: PropTypes.bool,
  onSlidesViewClick: PropTypes.func,
};

SermonSummary.defaultProps = {
  video: false,
  audio: false,
  slidesView: false,
  onSlidesViewClick: Function.prototype,
};

export default SermonSummary;
