import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from '@reach/router';
import Loadable from '@loadable/component';
import YouTube from 'react-youtube';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Paper, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { pluck, pick } from 'ramda';

import { items } from '../utils';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import SermonSummary from '../components/SermonSummary';
import MoreMedia from '../components/MoreMedia';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    paddingBottom: 'calc(56.25% + 18px)', // offset for negative marins
    height: 0,
    overflow: 'hidden',
    marginTop: theme.spacing(-6),
    marginBottom: theme.spacing(2),
    marginLeft: -1 * theme.spacing(2),
    marginRight: -1 * theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingBottom: 'calc(56.25% + 27px)', // offset for negative marins
      marginLeft: -1 * theme.spacing(3),
      marginRight: -1 * theme.spacing(3),
    },
    [theme.breakpoints.up('lg')]: {
      paddingBottom: '56.25%',
      marginTop: 0,
      marginBottom: theme.spacing(4),
      marginLeft: 0,
      marginRight: 0,
    },
  },
  player: {
    position: 'relative',
    paddingBottom: '56.25%',
    '& iframe': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: '#000',
    },
  },
  miniPlayer: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: '50%',
    marginRight: '-150px',
    [theme.breakpoints.up('sm')]: {
      right: theme.spacing(2),
      marginRight: 0,
    },
    zIndex: '1600',
    '& iframe': {
      display: 'block',
      width: '300px',
      height: '169px',
      backgroundColor: '#000',
    },
  },
  miniPlayerToolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: -30,
  },
  slidesViewer: ({ slidesView }) => ({
    display: slidesView ? 'block' : 'none',
  }),
}));

const SlidesViewer = Loadable(() => import('../components/SlidesViewer'));

const Page = ({ pageContext }) => {
  const [slidesView, setSlidesView] = useState(false);

  const classes = useStyles({ slidesView });

  const { search } = useLocation();
  const params = Object.fromEntries(new URLSearchParams(search));

  const opts = {
    height: '390',
    width: '640',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
      playsinline: 1,
      rel: 0,
      ...pick(['start', 'end'], params),
    },
  };

  const { sermon } = pageContext;

  return (
    <Layout>
      <SEO
        type="video"
        cardType="summary_large_image"
        title={sermon.title}
        description={`Sermon by ${pluck('name', items(sermon.speakers)).join(
          ', '
        )} at River of Life Christian Fellowship in Loveland, Colorado.`}
        image={`https://i.ytimg.com/vi/${sermon.youtubeId}/hqdefault.jpg`}
      />

      <div className={classes.wrapper}>
        <Box className={slidesView ? classes.miniPlayer : classes.player}>
          {slidesView && (
            <Paper className={classes.miniPlayerToolbar}>
              <IconButton size="small" onClick={() => setSlidesView(false)}>
                <CloseIcon />
              </IconButton>
            </Paper>
          )}
          <YouTube videoId={sermon.youtubeId} opts={opts} />
        </Box>

        {sermon.slides && (
          <div className={classes.slidesViewer}>
            <SlidesViewer file={sermon.slides} />
          </div>
        )}
      </div>

      <Box mb={8}>
        <SermonSummary
          video
          sermon={sermon}
          slidesView={slidesView}
          onSlidesViewClick={(event) => {
            event.preventDefault();
            setSlidesView(!slidesView);
          }}
        />
      </Box>

      <MoreMedia title="More Recent Media" currentSermon={sermon} />
    </Layout>
  );
};

Page.propTypes = {
  pageContext: PropTypes.object.isRequired,
};

export default Page;
